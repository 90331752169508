import { Form, Button, Table, Modal } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
// import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { cloneDeep, truncate } from "lodash";
import Select from "react-select";

import "../assets/scss/reports/cashbook.scss";
import { paginationOptions, defaultSelectValue } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import ReactPaginate from "react-paginate";
import { fetchActionsUtil } from "../utils/helpers";
import NoTableItem from "./utils/NoTableItem";
import useDebounce from "../utils/hooks";
import ConvertQuantity from "./utils/ConvertQuantity";
import { scrollToTop } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";
import ModalLoader from "./utils/ModalLoader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import currency from "currency.js";
import NumberCustomInput from "./utils/NumberCustomInput";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { isEmpty } from "lodash";

export default function AddStockCount({
  showAddStockCount,
  setShowAddStockCount,
  refetch,
}) {
  const { backendUrl, token } = useAuth();

  const [selectedData, setSelectedData] = useState([]);

  const [selectedAllData, setSelectedAllData] = useState([]);
  const [showSelected, setShowSeleted] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 100,
    q: "",
    model: "Items",
    product: "",
    category: "",
    withProduct: true,
    withCategory: true,
  };
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();

    data.product = [
      {
        label: "All",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "All",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    return data;
  };

  const { data = { count: 0, items: [] } } = useQuery(
    ["STOCK_COUNT_PRODUCTS", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const items = useMemo(() => {
    let arr = null;
    return arr;
  }, [data]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const createStockCountProduct = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/stock/add`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
        setShowAddStockCount(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const saveItems = async () => {
    const payload = {
      items: selectedData.filter((el) => el.QtyCounted !== null),
    };

    if (
      await ConfirmDialog({
        title: `Add Stock Count`,
        description: `Are you sure you want proceed?`,
      })
    ) {
      // console.log(payload);
      createStockCountProduct.mutate(payload);
    }
  };

  const onChangeQtyCounted = ({
    QtyCounted,
    Bar_Code,
    Quantity,
    Item_Name,
  }) => {
    setSelectedData((oldData) =>
      cloneDeep(
        oldData
          .filter((obj) => obj.Bar_Code !== Bar_Code)
          .concat({
            Bar_Code,
            QtyCounted,
            QtyInStock: Quantity,
            Item_Name,
          })
      )
    );
  };

  const debouncedSelectedData = useDebounce(selectedData, 500);

  const isEqualClass = useMemo(() => {
    const output = Object.assign(
      {},
      ...debouncedSelectedData.map((item) => {
        const foundSelected = debouncedSelectedData.find(
          (el) => el.Bar_Code === item.Bar_Code
        );
        if (isEmpty(foundSelected)) {
          return { [item.Bar_Code]: "" };
        } else if (
          Boolean(foundSelected.QtyCounted == foundSelected.QtyInStock)
        ) {
          return { [item.Bar_Code]: "correct" };
        }
        return { [item.Bar_Code]: "wrong" };
      })
    );
    return output;
  }, [debouncedSelectedData]);

  return (
    <Modal
      show={showAddStockCount}
      onHide={() => setShowAddStockCount(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Stock Count</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main className="cash-book p-0">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="text-nowrap px-0">
                  <div className="d-flex gap-2 align-items-center flex-grow">
                    <div className="global-search-area">
                      <MagnifyIcon />
                      <input
                        className="form-control search-input"
                        name="q"
                        value={filterParams?.q}
                        onChange={(e) => handleFilterParamsChange(e)}
                        placeholder="Search item..."
                        autoFocus
                        autoComplete="off"
                        type={"text"}
                      />
                    </div>

                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      name="product"
                      isSearchable={true}
                      key={data?.product}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          product: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.product,
                        data?.product,
                        { value: "", label: "Product" }
                      )}
                      options={data?.product || []}
                      isClearable
                    />

                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      className="mx-3"
                      menuPlacement="auto"
                      placeholder="All"
                      name="category"
                      isSearchable={true}
                      key={data?.category}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          category: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.category,
                        data?.category,
                        { value: "", label: "Category" }
                      )}
                      options={data?.category || []}
                      isClearable
                    />
                  </div>
                  <div className="actions">
                    <Button variant="primary" onClick={() => saveItems()}>
                      Post
                    </Button>
                  </div>
                </header>

                <div className="table-max mb-3">
                  <Table
                    borderless
                    responsive
                    striped
                    className="product-table"
                  >
                    <thead className="sticky border-bottom">
                      <tr>
                        <th>S/N</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>QTY in Stock</th>
                        <th>QTY Counted</th>
                      </tr>
                    </thead>

                    <tbody className="position-sticky top-0">
                      {data?.items &&
                        data?.items.map((el, index) => (
                          <tr
                            key={el?.Bar_Code}
                            className={isEqualClass[(el?.Bar_Code)]}
                          >
                            <td>{data?.startIndex + index + 1}</td>
                            <td>{el.Bar_Code}</td>
                            <td>{el?.Item_Name}</td>
                            <td className="p-cursor" title={el.Quantity}>
                              <ConvertQuantity
                                quantity={el.Quantity}
                                desc={el.Item_Desc}
                              />
                            </td>
                            <td>
                              <div
                                style={{
                                  width: "11rem",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <NumberCustomInput
                                  placeholder=""
                                  value={
                                    selectedData.find(
                                      (id) => id.Bar_Code === el.Bar_Code
                                    )?.QtyCounted
                                  }
                                  onValueChange={(value, name) =>
                                    onChangeQtyCounted({
                                      QtyCounted: value,
                                      Bar_Code: el.Bar_Code,
                                      Quantity: el.Quantity,
                                      Item_Name: el.Item_Name,
                                    })
                                  }
                                />{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>
      <ModalLoader show={createStockCountProduct.isLoading} />
    </Modal>
  );
}
