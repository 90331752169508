import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
// import { CSVLink } from "react-csv";
// import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
// import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { ExchangeFunds, EditIcon, DeleteIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import {
  fetchActionsUtil,
  getUniqueObjectsFromArray,
} from "../../utils/helpers";
import CreateStorageLocationModal from "../modals/CreateStorageLocation";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";

function StorageLocation() {
  useScrollTop();
  const initialFilterParams = {
    startDate: "",
    endDate: "",
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [allStorage, setAllStorage] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("All");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [pickedStorage, setPickedStorage] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((query) => ({
      ...query,
      ...debouncedFilterParams,
    }));
  }, [debouncedFilterParams, setQueryParams]);

  const fechWareHouse = async (query) => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation?${queryString.stringify(query)}`,
      "GET"
    );

    let warehouse = data?.storagelocation.map((wh) => wh?.WH_details);
    warehouse = getUniqueObjectsFromArray(warehouse);

    data.warehouse = [
      {
        value: "All",
        label: "Select Warehouse",
      },
      ...warehouse.map((el) => ({
        value: el.W_ID,
        label: el.W_name,
      })),
    ];

    setAllStorage(() => data?.storagelocation);
    return data;
  };

  const { data, refetch, isFetching } = useQuery(
    ["FETCH_STORAGE-LOCATION", queryParams],
    () => fechWareHouse(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const onSelectStorage = async (store) => {
    if (store === "All") return setAllStorage(() => data?.storagelocation);
    const storage = data?.storagelocation?.filter((el) => el?.W_name === store);
    setAllStorage(() => storage);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const deleteStorageLocation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/StorageLocation/delete/${payload}`,
        "POST"
      ),
    {
      onSuccess: () => {
        toast.success("Storage Location deleted Successfully");
        refetch();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!");
      },
    }
  );

  const deleteStorageLocationAction = async (id) => {
    if (
      await ConfirmDialog({
        title: `Delete Storage Location`,
        description: `Are you sure you want to DELETE this storage location `,
      })
    ) {
      deleteStorageLocation.mutate(id);
    }
  };

  return (
    <main className="cash-book table-sticky-header">
      <div className="content">
        <header className="container">
          <h1>
            Storage Location
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h1>

          <div className="actions mr-5">
            <div style={{ width: "12rem" }}>
              <Select
                classNamePrefix="form-select"
                value={data?.warehouse?.find(
                  (d) => d.value === selectedWarehouse
                )}
                options={data?.warehouse}
                onChange={(selected) => {
                  setSelectedWarehouse(selected.value);
                  onSelectStorage(selected.value);
                }}
              />
            </div>
            <div className="position-relative">
              <RsDateRangePicker
                placement="bottomEnd"
                value={
                  filterParams.startDate && filterParams.endDate
                    ? [
                        parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
            </div>

            <button
              onClick={() => setOpenCreateModal(true)}
              className="btn bg-primary text-white"
            >
              Create New <ExchangeFunds color="white" />
            </button>
          </div>
        </header>

        <div>
          <Table
            // responsive
            borderless
            striped
            className="product-table container"
          >
            <thead className="position-sticky top-0">
              <tr>
                <th />
                <th>Storage Location</th>
                <th>WareHouse Name</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {allStorage?.map((ek, i) => (
                <tr key={i}>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-white border-0"
                        bsPrefix="print more"
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        <Dropdown.Item
                          as="div"
                          className="p-cursor"
                          onClick={() => {
                            setPickedStorage({
                              Storage_ID: ek.Storage_ID,
                              W_name: ek.W_name,
                              storage_location: ek.storage_location,
                            });
                            setOpenEditModal(true);
                          }}
                        >
                          <EditIcon /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="div"
                          onClick={() => {
                            deleteStorageLocationAction(ek.Storage_ID);
                          }}
                          className="p-cursor"
                        >
                          <DeleteIcon color="#94A3B8" />
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{ek.storage_location}</td>
                  <td>{ek.WH_details.W_name}</td>
                  <td>{ek.Storage_date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {allStorage?.length < 1 && <NoTableItem queryParams={queryParams} />}
        <div className="pagination">
          {/* Left */}
          {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

          {/* right */}
          {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
        </div>
      </div>
      <ModalLoader show={isFetching} />
      {openCreateModal && (
        <CreateStorageLocationModal
          show={openCreateModal}
          onHide={() => setOpenCreateModal(false)}
          refetch={refetch}
        />
      )}
      {openEditModal && (
        <CreateStorageLocationModal
          show={openEditModal}
          onHide={() => setOpenEditModal(false)}
          refetch={refetch}
          editStorage={true}
          pickedStorage={pickedStorage}
        />
      )}
    </main>
  );
}

export default StorageLocation;
