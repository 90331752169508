import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import Select from "react-select";

const CreateStorageLocationModal = (props) => {
  const { backendUrl } = useAuth();

  const getWarehouses = async () => {
    const warehouses = await fetchActionsUtil(
      `${backendUrl}/api/warehouse`,
      "GET"
    );

    // console.log(warehouses);
    warehouses.warehouses = warehouses.warehouses.map((el) => ({
      value: el.W_ID,
      label: el.W_name,
    }));

    return warehouses;
  };

  const { data, isFetching } = useQuery(
    ["FETCH_WAREHOUSE", useQuery],
    () => getWarehouses(),
    {
      keepPreviousData: true,
    }
  );

  //   console.log(data);

  const saveStorageLocation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/StorageLocation`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Storage Location saved Successfully");
        formik.resetForm({
          W_name: "",
          storage_location: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: () => {
        toast.error("Error!!!");
      },
    }
  );

  const editStorageLocation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/StorageLocation/edit/${
          props?.pickedStorage?.Storage_ID
        }`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Storage Location updated Successfully");
        formik.resetForm({
          W_name: "",
          storage_location: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      W_name: "",
      storage_location: "",
    },
    validationSchema: yup.object().shape({
      W_name: yup.string().required("Warehouse Name is required"),
      storage_location: yup.string().required("Storage Location is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: `${props?.editStorage ? "Edit" : "Create"} Storage Location`,
          description: `Are you sure you want to ${
            props?.editStorage ? "Edit" : "Create"
          } this storage location `,
        })
      ) {
        // console.log(values);
        if (props?.editStorage) {
          editStorageLocation.mutate(values);
        } else {
          saveStorageLocation.mutate(values);
        }
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "W_name",
      props?.StorageWarehouseName ? props.StorageWarehouseName : ""
    );

    if (props?.editStorage) {
      formik.setValues({
        W_name: props?.pickedStorage?.W_name,
        storage_location: props?.pickedStorage?.storage_location,
      });
    }
  }, []);

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>{props?.editStorage ? "Edit" : "Create"} Storage Location</h6>
              <div
                role="button"
                className="text-lg text-white rounded-circle bg-light px-2"
                onClick={props.onHide}
              >
                x
              </div>
            </div>
            <p className="text-muted">
              {props?.editStorage ? "Edit" : "Create a new"} Storage Location
            </p>
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">
                Storage Location Name
              </label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={formik.values.storage_location}
                  name="storage_location"
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.storage_location}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Warehouse Name</label>
              <div className="form-group">
                <Select
                  classNamePrefix="form-select"
                  value={data?.warehouses?.find(
                    (d) => d.value === formik.values.W_name
                  )}
                  options={data?.warehouses}
                  onChange={(selected) => {
                    formik.setFieldValue("W_name", selected.value);
                  }}
                />

                <span className="form-control-feedback text-danger">
                  {formik.errors.W_name}
                </span>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={formik.handleSubmit}>
          {props?.editStorage ? "Edit" : "Save"} Storage Location
        </button>
      </div>
      <ModalLoader show={isFetching || saveStorageLocation.isLoading} />
    </Modal>
  );
};

export default CreateStorageLocationModal;
